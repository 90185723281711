import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <section id="footer">
      <div className="wrapper2">
        <div className="inner">
          <h2 className="majorCont">Get in touch</h2>

          <form
            action={`https://formspree.io/xknqgrey`}
            method="POST"
            name="contact"
            data-netlify="true"
          >
            <div className="fields">
              <div className="field">
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" />
              </div>
              <div className="field">
                <label htmlFor="email">Email</label>
                <input type="email" name="_replyto" id="email" />
              </div>
              <div className="field">
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" rows="4"></textarea>
              </div>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" />
              </li>
            </ul>
          </form>
          <ul className="contact">
            {/*<li className="fa-home">{config.address}</li>

  <li className="fa-phone">{config.phone}</li>*/}

            {config.socialLinks.map(social => {
              const { icon, url } = social;
              return (
                <li className={`${icon}`} key={url}>
                  <a href={url}>{url}</a>
                </li>
              );
            })}
          </ul>
          <ul className="copyright">
            <li>
              <a href="#" target="_blank" rel="noopener">
                &copy; Top Hat Unicorn Productions,{' '}
              </a>
              <a href="http://html5up.net" target="_blank" rel="noopener">
                HTML5 UP
              </a>
              . All rights reserved.
            </li>
            {/*<li>
              Design: Tophat Unicorn Productions, and{' '}
              
            </li>*/}
          </ul>
        </div>
      </div>
    </section>
  );
}
