module.exports = {
  siteTitle: 'Tophat Unicorn Productions', // <title>
  manifestName: 'Tophat Unicorn Productions',
  manifestShortName: 'Tophat Unicorn Productions', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/tophatunicorn/`, // This path is subpath of your hosting https://domain/portfolio
  //heading: 'Tophat Unicorn Productions',
  subHeading: 'We build websites!',
  // social
  socialLinks: [
    /*{
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'dd',
    },*/

    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:info@tophatunicorn.com',
    },
    /*{
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'facebook',
    },*/
  ],
  /*phone: 'Do you mind having your phone number here?',
  address: 'Do we want to put a physical address here?',*/
};
